<template>
  <b-card no-body>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Marque : </label>
          <v-select
            v-if="marquesOptions.length"
            :value="selectedMarqueId"
            :options="marquesOptions"
            :reduce="marque => marque.id"
            label="nom"
            class="w-100 select-cursor"
            :clearable="false"
            @input="(val) => $emit('update:selectedMarqueId', val)"
          ><div slot="no-options">
            Aucune option
          </div></v-select>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import sortByName from '@/utils/SortListByName'
import store from '@/store'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    vSelect,
  },
  props: {
    selectedMarqueId: {
      type: [Number, null],
      default: null,
    },
  },
  data() {
    return {
      marquesOptions: [],
    }
  },
  mounted() {
    this.$nextTick(() => {
      store.dispatch('modeles/initMarques')
        .then(response => {
          this.marquesOptions = sortByName(response.data)
          if (this.selectedMarqueId === null && this.marquesOptions.length > 0) {
            this.$emit('update:selectedMarqueId', this.marquesOptions[0].id)
          }
        })
    })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
