import useList from '@/views/hooks/useList'
import store from '@/store'
import { ref, watch } from '@vue/composition-api'
import router from '@/router'
import ability from '@/libs/acl/ability'

export default function useModelesList() {
  // DATA
  const selectedMarqueId = ref(router.currentRoute.params.id)

  const list = useList({
    fetchAction: () => {
      if (!selectedMarqueId.value) {
        return []
      }
      return store.dispatch('modeles/initModeles', selectedMarqueId.value)
    },
    deleteActionName: 'modeles/deleteModele',
  })

  watch([selectedMarqueId], selectedMarqueIdValue => {
    if (selectedMarqueIdValue !== null) {
      list.refetchData()
    }
  })

  const tableColumns = [
    {
      label: 'Nom', key: 'nom', sortable: true,
    },
  ]

  if (ability.can('update', 'Modele') || ability.can('delete', 'Modele')) {
    tableColumns.push({ label: 'Actions', key: 'actions' })
  }

  return {
    ...list,
    tableColumns,
    selectedMarqueId,
  }
}
